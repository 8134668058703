import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import SEO from '../../components/SEO';
import LinkTo from '../../components/LinkTo';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import ShapeTitle from '../../components/ShapeTitle/ShapeTitle';
import { Product, Variant } from '../../modules/product/product';
import * as price from '../../modules/product/price';

export const query = graphql`
  query {
    products: allCatalogue(sort: { fields: [position] }) {
      nodes {
        name
        variants {
          id
          name
          canonical
          price
          public
          stock
          images {
            childImageSharp {
              fluid(maxWidth: 345, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  data: {
    products: {
      nodes: Product[];
    };
  };
};

type VariantWithProductInformation = Variant & {
  productName: string;
  inStock: boolean;
};

const filterProductVariants = (
  products: Product[],
  fn: (variant: Variant) => boolean
): VariantWithProductInformation[] => {
  return products.flatMap<VariantWithProductInformation>((product) =>
    product.variants.filter(fn).map((variant) => ({
      ...variant,
      productName: product.name,
      inStock: variant.stock > 0,
    }))
  );
};

const ShopPage: React.FC<Props> = ({ data }) => {
  const products = data.products.nodes.map((product) => ({
    ...product,
    variants: product.variants.filter((variant) => variant.public),
  }));

  const inStock = filterProductVariants(
    products,
    (variant) => variant.stock > 0
  );

  const outStock = filterProductVariants(
    products,
    (variant) => variant.stock <= 0
  );

  return (
    <Layout
      title={
        <React.Fragment>
          Jouer avec une belle poupée française
          <br className="none md:block" /> et sa garde-robe éthique
        </React.Fragment>
      }
      crumbs={[
        { name: 'Accueil', path: '/' },
        { name: 'Shop', path: '/shop/' },
      ]}
    >
      <SEO
        title="Tous les produits | Poupée Odette"
        description="Une garde-robe stylée et éthique, des vêtements de poupées bio, certifiés OEKO-TEK® ou GOTS, fabriqués à la main à 100% en France."
      />

      <Section className="-mrb-25x">
        <div className="flex flex-wrap flex-column justify-around mw-980x mrr-auto mrl-auto -mrb-25x sm:flex-row">
          <div className="mw-225x mrb-35x mrl-20x sm:mrb-0 sm:mrl-0">
            <ShapeTitle name="shopCloset">Une garde robe</ShapeTitle>
            <p className="fsz-md pdl-10x">
              Pour s’amuser à habiller la poupée en fonction des saisons et des
              moments de la journée.
            </p>
          </div>

          <div className="mw-245x mrb-40x mrr-20x self-end sm:mrr-0 sm:self-auto sm:pdt-40x">
            <ShapeTitle name="shopStitch">Cousue à la main</ShapeTitle>
            <p className="fsz-md pdl-10x">
              Chaque vêtement ou accessoire est cousu et brodé à la main avec
              amour en Île-de-France ! Ils sont tous uniques.
            </p>
          </div>

          <div className="mw-250x mrb-20x mrl-20x sm:mrl-0 sm:pdt-10x">
            <ShapeTitle name="shopMaterials">
              Avec des matières saines
            </ShapeTitle>
            <p className="fsz-md pdl-10x">
              Chaque pièce est fabriquée dans des matières naturelles,
              certifiées{' '}
              <LinkTo className="current" address="https://www.oeko-tex.com">
                OEKO-TEX
              </LinkTo>
              <sup>®</sup> ou{' '}
              <LinkTo
                className="current"
                address="https://www.global-standard.org"
              >
                GOTS
              </LinkTo>
              .
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <ul className="flex flex-wrap justify-between mw-980x mrr-auto mrl-auto pdl-10x pdr-10x after:no-content after:flex after:calc after:mrb-30x md:after:w-1/3 md:pdl-0 md:pdr-0">
          {inStock.concat(outStock).map((variant) => (
            <li key={variant.id} className="w-calc mrb-30x md:w-1/3">
              <Link
                className="current td-none"
                to={`/shop/${variant.canonical}/`}
              >
                <div className="relative overflow-hidden brr-2x mrb-10x">
                  <Image
                    fluid={variant.images[0].childImageSharp.fluid}
                    draggable={false}
                  />
                </div>
                <p className="ff-lora fsz-lg fw-bold fsy-italic blue">
                  {variant.productName}
                </p>
                {variant.name && (
                  <p className="fsz-md fw-bold mrb-2x">{variant.name}</p>
                )}
                <p className="fsz-md fw-bold">
                  {variant.inStock
                    ? `${price.format(variant.price)} €`
                    : 'Mince ! Épuisé'}
                </p>
              </Link>
            </li>
          ))}
        </ul>
      </Section>
    </Layout>
  );
};

export default ShopPage;
